



























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220S4_Q10',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
        studentAnswerC: null,
        studentAnswerD: null,
      },
      questions: [
        {text: 'a) ^{60}_{27}Co^*  -> ^{60}_{27}Co + \\gamma', answer: 'studentAnswerA'},
        {text: 'b) ^{64}_{29}Cu  -> ^{64}_{28}Ni  +  ^{0}_{1}\\beta^{+}', answer: 'studentAnswerB'},
        {text: 'c) ^{222}_{86}Rn -> ^{218}_{84}Po + ^{4}_{2}He', answer: 'studentAnswerC'},
        {
          text: 'd) ^{231}_{90}Th -> ^{231}_{91}Pa  +  + ^{0}_{-1}\\beta^{-}',
          answer: 'studentAnswerD',
        },
      ],
      options: [
        {
          text: '$\\alpha$-emission',
          value: 'alpha',
        },
        {
          text: '$\\beta^+$ decay',
          value: 'betaPlus',
        },
        {
          text: '$\\beta^-$ decay',
          value: 'betaMinus',
        },
        {
          text: 'Electron capture',
          value: 'electron',
        },
        {
          text: '$\\gamma$-emission',
          value: 'gamma',
        },
      ],
    };
  },
};
